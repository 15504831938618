<style type="text/scss">svg {
  max-width: 50vw;
  margin: 0 auto;
  display: block; }
  @media (min-width: 768px) {
    svg {
      max-width: 250px; } }
</style>

<svg
  id="OBJECTS"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 296.32 162.23">
  <defs>
    <style>
      .cls-1 {
        fill: #1d4259;
      }
      .cls-2 {
        fill: #0f222c;
      }
      .cls-3 {
        fill: #e8bf7c;
      }
      .cls-4 {
        fill: #d0a569;
      }
      .cls-5 {
        fill: #ab7a45;
      }
      .cls-6 {
        fill: #d0a56b;
      }
      .cls-7 {
        fill: #986e3e;
      }
      .cls-8 {
        fill: #a87944;
      }
      .cls-9 {
        fill: #ff524f;
      }
      .cls-10 {
        fill: #f2f2f2;
      }
      .cls-11 {
        fill: #ce3836;
      }
      .cls-12 {
        fill: #222846;
      }
      .cls-13 {
        fill: #1b3241;
      }
    </style>
  </defs>
  <path
    class="cls-1"
    d="M398.16,406.24c0,2.09-74.39,3.78-166.16,3.78s-130.15-1.69-130.15-3.78,38.39-3.78,130.15-3.78S398.16,404.15,398.16,406.24Z"
    transform="translate(-101.84 -247.79)" />
  <path
    class="cls-2"
    d="M151.29,365.46a21.26,21.26,0,0,1,0,42.53h-5.89V365.46h5.89Z"
    transform="translate(-101.84 -247.79)" />
  <path
    class="cls-2"
    d="M262.29,365.46a21.26,21.26,0,0,1,0,42.53h-5.89V365.46h5.89Z"
    transform="translate(-101.84 -247.79)" />
  <path
    class="cls-2"
    d="M151.29,365.46a21.26,21.26,0,0,1,0,42.53h-5.89V365.46h5.89Z"
    transform="translate(-101.84 -247.79)" />
  <circle class="cls-2" cx="89.67" cy="134.96" r="21.26" />
  <path
    class="cls-2"
    d="M310.92,361.5a21.26,21.26,0,0,1,0,42.52H305V361.5h5.9Z"
    transform="translate(-101.84 -247.79)" />
  <circle class="cls-2" cx="203.18" cy="134.96" r="21.26" />
  <rect
    class="cls-3"
    x="256.39"
    y="247.79"
    width="109.05"
    height="37.66"
    transform="translate(519.99 285.46) rotate(180)" />
  <rect
    class="cls-3"
    x="212.77"
    y="285.36"
    width="87.24"
    height="87.24"
    transform="translate(410.94 410.16) rotate(180)" />
  <rect
    class="cls-4"
    x="249.69"
    y="285.36"
    width="13.4"
    height="87.24"
    transform="translate(483.53 -175.21) rotate(90)" />
  <rect
    class="cls-5"
    x="247.29"
    y="322.28"
    width="18.2"
    height="13.4"
    transform="translate(410.94 410.16) rotate(180)" />
  <rect
    class="cls-4"
    x="249.69"
    y="285.36"
    width="13.4"
    height="87.24"
    transform="translate(410.94 410.16) rotate(180)" />
  <rect
    class="cls-6"
    x="300.01"
    y="285.36"
    width="65.43"
    height="87.24"
    transform="translate(563.61 410.16) rotate(180)" />
  <rect
    class="cls-5"
    x="326.03"
    y="296.26"
    width="13.4"
    height="65.43"
    transform="translate(559.86 -251.54) rotate(90)" />
  <rect
    class="cls-7"
    x="323.36"
    y="322.28"
    width="18.2"
    height="13.4"
    transform="translate(563.08 410.16) rotate(180)" />
  <polygon
    class="cls-8"
    points="198.17 37.66 263.6 37.66 296.32 5.14 230.89 5.14 198.17 37.66" />
  <rect class="cls-5" x="223.92" y="37.56" width="13.4" height="87.24" />
  <polygon
    class="cls-7"
    points="223.92 37.56 237.32 37.56 269.74 5.14 256.34 5.14 223.92 37.56" />
  <polygon
    class="cls-6"
    points="198.17 37.56 110.93 37.56 78.22 4.85 165.46 4.85 198.17 37.56" />
  <polygon
    class="cls-5"
    points="161.25 37.56 147.85 37.56 115.14 4.85 128.54 4.85 161.25 37.56" />
  <path
    class="cls-1"
    d="M101.84,372.6h194a4.2,4.2,0,0,1,4.2,4.2v5.74a4.2,4.2,0,0,1-4.2,4.2h-194a0,0,0,0,1,0,0V372.6A0,0,0,0,1,101.84,372.6Z"
    transform="translate(300.01 511.53) rotate(180)" />
  <path
    class="cls-9"
    d="M106,372.6H212.77V285.36H159.29a31.43,31.43,0,0,0-26,13.83l-21.82,32.29a31.43,31.43,0,0,0-5.39,17.6V372.6Z"
    transform="translate(-101.84 -247.79)" />
  <path
    class="cls-10"
    d="M187.16,337v-41.8H159.29a21.59,21.59,0,0,0-17.91,9.51L119.56,337h67.59Z"
    transform="translate(-101.84 -247.79)" />
  <rect
    class="cls-11"
    x="199.92"
    y="285.36"
    width="12.85"
    height="87.24"
    transform="translate(310.85 410.16) rotate(180)" />
  <path
    class="cls-2"
    d="M255.41,361.5a25.23,25.23,0,0,1,25.23,25.23H230.18A25.23,25.23,0,0,1,255.41,361.5Z"
    transform="translate(-101.84 -247.79)" />
  <path
    class="cls-2"
    d="M144.41,361.5a25.23,25.23,0,0,1,25.23,25.23H119.18A25.23,25.23,0,0,1,144.41,361.5Z"
    transform="translate(-101.84 -247.79)" />
  <circle class="cls-12" cx="154.55" cy="138.93" r="21.26" />
  <circle class="cls-10" cx="154.55" cy="138.93" r="10.46" />
  <circle class="cls-12" cx="43.55" cy="138.93" r="21.26" />
  <circle class="cls-10" cx="43.55" cy="138.93" r="10.46" />
  <rect
    class="cls-11"
    x="300.01"
    y="357.16"
    width="9.11"
    height="8.67"
    transform="translate(507.3 475.2) rotate(180)" />
  <rect
    class="cls-9"
    x="290.9"
    y="357.16"
    width="9.11"
    height="8.67"
    transform="translate(489.07 475.2) rotate(180)" />
  <rect
    class="cls-11"
    x="356.33"
    y="357.16"
    width="9.11"
    height="8.67"
    transform="translate(619.93 475.2) rotate(180)" />
  <path
    class="cls-11"
    d="M71.6,92.68H85.31a0,0,0,0,1,0,0v1.91a2.66,2.66,0,0,1-2.66,2.66H74.26a2.67,2.67,0,0,1-2.67-2.67V92.68A0,0,0,0,1,71.6,92.68Z" />
  <path
    class="cls-12"
    d="M71.6,92.68H85.31a0,0,0,0,1,0,0v0.78a2.66,2.66,0,0,1-2.66,2.66H74.26a2.67,2.67,0,0,1-2.67-2.67V92.68A0,0,0,0,1,71.6,92.68Z" />
  <path
    class="cls-10"
    d="M114.32,361.5a8.28,8.28,0,0,0-8.28-8.28v16.57A8.28,8.28,0,0,0,114.32,361.5Z"
    transform="translate(-101.84 -247.79)" />
  <rect
    class="cls-13"
    x="193.97"
    y="124.8"
    width="73.82"
    height="14.13"
    rx="4.2"
    ry="4.2" />
  <circle class="cls-10" cx="202.73" cy="131.87" r="3" />
  <circle class="cls-10" cx="209.4" cy="131.87" r="1.5" />
  <circle class="cls-10" cx="259.04" cy="131.87" r="3" />
  <circle class="cls-10" cx="252.37" cy="131.87" r="1.5" />
</svg>
