<style type="text/scss">svg {
  margin: 0 auto 0.5rem auto;
  max-width: 120px;
  width: 100%; }
  @media (min-width: 768px) {
    svg {
      margin: 0; } }
</style>

<svg
  id="Livello_1"
  data-name="Livello 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 517.23 247.65">
  <defs>
    <style>
      .dls-2 {
        fill: #fff;
      }
    </style>
  </defs>
  <path
    class="dls-2"
    d="M380,150.32h1.27c10.28,0,20.56,0,30.83,0a1.87,1.87,0,0,1,1.85,1.1Q428.1,175.23,442.25,199a5.4,5.4,0,0,0,.37.46c-1.51-16.42-.45-32.73-0.88-49.09h32.8v94.67h-1.34c-10.32,0-20.64,0-31,0a2,2,0,0,1-2-1.16q-13.91-23.59-27.88-47.14c-0.11-.19-0.26-0.37-0.56-0.79,0.23,2.22.53,4.13,0.6,6,0.16,4.75.28,9.51,0.3,14.27,0,9,0,18.08,0,27.11V245H380V150.32Z" />
  <path
    class="dls-2"
    d="M375.7,197.83c-0.19,24.67-16.6,44.43-41,48.85-15.12,2.73-29.14-.19-41.48-9.55-11.45-8.69-17.62-20.39-18.73-34.74-0.77-9.94.63-19.51,5.45-28.3,7.57-13.83,19.35-22.07,34.8-24.93,11.42-2.11,22.58-1,33.1,4.06,15.1,7.32,24.18,19.33,27,35.93C375.29,192,375.41,194.93,375.7,197.83Zm-34.66,0A53.77,53.77,0,0,0,340,191.4c-1.74-6-5.5-10.13-11.8-11.25-6.58-1.17-12.3.55-16.16,6.33s-4.07,12-2.17,18.31c1.74,5.79,5.65,9.62,11.76,10.69s11.45-.34,15.48-5.34C339.92,206.58,340.82,202.37,341,197.87Z" />
  <path
    class="dls-2"
    d="M192.5,245V150.38c0.41,0,.83-0.06,1.25-0.06q21.23,0,42.47,0c7.42,0,14.68,1,21.44,4.27,9.33,4.52,14.4,12.07,15.62,22.3a33.75,33.75,0,0,1-1.16,15.17c-2.68,7.8-7.86,12.82-16.21,14.2a1.49,1.49,0,0,0-.34.12,1.06,1.06,0,0,0-.15.24l25.16,38.44h-1.27c-11.88,0-23.75,0-35.63,0a1.78,1.78,0,0,1-1.83-1.13Q234.26,229.49,226.6,215a2.2,2.2,0,0,0-.78-0.91V245H192.5Zm33.34-52.11a63.37,63.37,0,0,0,8.19-.06c4.43-.59,6.83-3.37,7.27-7.77,0.57-5.76-2.61-9.72-8.37-10.25-2-.18-4-0.06-6-0.17-1-.05-1.19.35-1.18,1.25,0,5.36,0,10.71,0,16.07C225.78,192.27,225.83,192.62,225.85,192.86Z" />
  <path
    class="dls-2"
    d="M0,150.32H1.29q20.22,0,40.43,0a65.89,65.89,0,0,1,20.21,2.83c11,3.53,17.33,11.11,19.29,22.4a51.17,51.17,0,0,1-.29,19.78C78.7,205.59,72.38,212,62.33,214.87A62.08,62.08,0,0,1,46.1,217c-3.56.05-7.12,0-10.68,0H34v28H0V150.32Zm34.22,26.25v16a85.27,85.27,0,0,0,9.31-.71,6.9,6.9,0,0,0,6.05-7.09c0.07-3.76-1.92-6.45-5.7-7.59C40.73,176.22,37.5,176.62,34.22,176.57Z" />
  <path
    class="dls-2"
    d="M124.55,150.36h63.16v25.58H157.88v9.13h27V209.3h-27v10h30.56V245H124.55V150.36Z" />
  <path
    class="dls-2"
    d="M428.64,155l-6.05-7.47c-9.85-12.15-19.73-24.27-29.53-36.45-4.17-5.18-8.28-10.43-12.22-15.79a53.21,53.21,0,0,1-6.93-13.37,51.72,51.72,0,0,1-2.72-10.81c-0.71-5.22-1.4-10.44-1-15.72a70.82,70.82,0,0,1,1.56-10.71,59.26,59.26,0,0,1,4-11.41,56.55,56.55,0,0,1,9.75-14.36,57.42,57.42,0,0,1,18-13.2,80.37,80.37,0,0,1,9.25-3.33A52.12,52.12,0,0,1,429.08,0a56.32,56.32,0,0,1,10.06,1.14,71,71,0,0,1,10.21,2.65,53.17,53.17,0,0,1,12.75,6.62,58,58,0,0,1,18.28,20.36A55.34,55.34,0,0,1,485,42.32a59,59,0,0,1,2.11,21.13,88.68,88.68,0,0,1-1.77,12.38,58,58,0,0,1-10.56,22.05c-5.55,7.25-11.38,14.29-17.11,21.4q-9.76,12.11-19.55,24.18L429.59,154C429.34,154.28,429.05,154.55,428.64,155ZM379.76,59.55a49,49,0,0,0,48.77,49c26.71,0.07,48.91-21.88,49-48.42A48.88,48.88,0,1,0,379.76,59.55Z" />
  <path class="dls-2" d="M84.71,150.36h34.38V245H84.71v-94.6Z" />
  <path class="dls-2" d="M517.23,245h-34.4v-94.6h34.4V245Z" />
  <path
    class="dls-2"
    d="M379.76,59.55a48.88,48.88,0,1,1,97.76.55c-0.07,26.54-22.28,48.48-49,48.42A49,49,0,0,1,379.76,59.55Zm60.44,3.11v39.62C456.39,98.39,471.84,83,472.86,62a44.11,44.11,0,0,0-38.45-46.13c-21-2.75-38.05,9.49-44.63,22.88l1.24-1q8.78-7.16,17.55-14.34a1,1,0,0,1,1.59,0q22.67,19.16,45.38,38.27a4.38,4.38,0,0,0,1,.49l-0.41.53h-16Zm-24,39.49V91H400.79a8.52,8.52,0,0,1,0,1.4,2.52,2.52,0,0,0,1.44,2.84c1,0.59,1.9,1.34,2.88,1.95A42.56,42.56,0,0,0,416.25,102.14Z" />
</svg>
